














import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import TransactionStateFlow from './TransactionStateFlow.vue';

@Component({
  components: {
    TransactionStateFlow,
  },
})
export default class TransactionState extends BaseVue {
  @Prop() className?: string | null;
  @Prop() state!: string;

  public get steps() {
    let currentIndex = 0;
    let text = '';
    let isError = false;
    let isSuspended = false;
    switch (this.state) {
      case 'new':
        currentIndex = 0;
        text = '';
        break;
      case 'ready-to-price':
        currentIndex = 1;
        text = 'Ready to Price';
        break;
      case 'failed-to-price':
        currentIndex = 1;
        text = 'Failed to Price';
        isError = true;
        break;
      case 'priced':
        currentIndex = 2;
        text = '';
        isError = false;
        break;
      case 'categorized':
        currentIndex = 3;
        text = '';
        break;
      case 'open-needs-review':
        currentIndex = 4;
        text = 'Open Needs Review';
        break;
      case 'ready-to-sync':
        currentIndex = 4;
        text = 'Ready to Sync';
        break;
      case 'syncing':
        currentIndex = 4;
        text = 'Syncing';
        break;
      case 'synced':
        currentIndex = 5;
        text = '';
        break;
      case 'failed-to-sync':
        currentIndex = 4;
        text = 'Failed to Sync';
        isError = true;
        break;
      case 'marked-synced':
        currentIndex = 5;
        text = 'Marked Synced';
        break;
      case 'closed-needs-review':
        currentIndex = 5;
        text = 'Closed Needs Review';
        break;
      case 'ignored':
        currentIndex = 0;
        text = 'Ignored';
        isSuspended = true;
        break;
      case 'deleted':
        currentIndex = 0;
        text = 'Deleted';
        isSuspended = true;
        break;
      case 'combined':
        currentIndex = 0;
        text = 'Combined into New Transaction';
        isSuspended = true;
        break;
      case 'new-needs-review':
        currentIndex = 0;
        text = 'New Needs Review';
        isSuspended = true;
        break;
      default:
        currentIndex = 0;
        text = 'Unknown';
        isSuspended = true;
        break;
    }

    const steps = [
      { name: 'Discovered', id: 0, isCurrentStep: false, isSuspended: false },
      { name: 'Ready to Price', id: 1, isHidden: true, isError: false, isCurrentStep: false },
      { name: 'Priced', id: 2, isCurrentStep: false },
      { name: 'Categorized', id: 3, isCurrentStep: false },
      { name: 'Syncing', id: 4, isHidden: true, isError: false, isCurrentStep: false },
      { name: 'Synced', id: 5, isCurrentStep: false },
    ];
    if (text) steps[currentIndex].name = text;
    if (isError) steps[currentIndex].isError = true;
    if (isSuspended) steps[currentIndex].isSuspended = true;
    steps[currentIndex].isCurrentStep = true;
    return steps;
  }

  public get iconProps() {
    switch (this.state) {
      case 'new':
        return { text: 'New', icon: 'fa-solid fa-star-of-life', subIcon: '', iconClass: 'tw-text-gray-400' };
      case 'ready-to-price':
        return {
          text: 'Ready to Price',
          icon: 'fa-solid fa-dollar-sign',
          subIcon: 'fa-solid fa-hourglass',
          iconClass: 'tw-text-white tw-bg-tertiary-300 tw-rounded-full tw-px-1 tw-py-0.5',
          subIconClass: 'tw-text-gray-400',
          styleName: 'font-size: x-small',
        };
      case 'failed-to-price':
        return {
          text: 'Failed to Price',
          icon: 'fa-solid fa-question-circle',
          subIcon: 'fa-solid fa-triangle-exclamation',
          iconClass: 'tw-text-tertiary-300',
          subIconClass: 'tw-text-error-300',
        };
      case 'priced':
        return { text: 'Priced', icon: 'fa-solid fa-question-circle', subIcon: '', iconClass: 'tw-text-tertiary-300' };
      case 'categorized':
        return { text: 'Categorized', icon: 'fa-solid fa-check-circle', subIcon: '', iconClass: 'tw-text-success-300' };
      case 'open-needs-review':
        return {
          text: 'Open Needs Review',
          icon: 'fa-solid fa-triangle-exclamation',
          subIcon: '',
          iconClass: 'tw-text-primary-300',
        };
      case 'ready-to-sync':
        return {
          text: 'Ready to Sync',
          icon: 'fa-solid fa-rotate',
          subIcon: 'fa-solid fa-hourglass',
          iconClass: 'tw-text-primary-300',
          subIconClass: 'tw-text-gray-400',
        };
      case 'syncing':
        return { text: 'Syncing', icon: 'fa-solid fa-rotate', subIcon: '', iconClass: 'tw-text-primary-300' };
      case 'synced':
        return { text: 'Synced', icon: 'fa-solid fa-check-circle', subIcon: '', iconClass: 'tw-text-primary-300' };
      case 'failed-to-sync':
        return {
          text: 'Failed to Sync',
          icon: 'fa-solid fa-rotate',
          subIcon: 'fa-solid fa-exclamation-circle',
          iconClass: 'tw-text-error-300',
          subIconClass: 'tw-text-error-300',
        };
      case 'marked-synced':
        return {
          text: 'Marked Synced',
          icon: 'fa-solid fa-check-circle',
          subIcon: 'fa-solid fa-pen-to-square',
          iconClass: 'tw-text-primary-300',
          subIconClass: 'tw-text-primary-300',
        };
      case 'closed-needs-review':
        return {
          text: 'Closed Needs Review',
          icon: 'fa-solid fa-check-circle',
          subIcon: 'fa-solid fa-triangle-exclamation',
          iconClass: 'tw-text-primary-300',
          subIconClass: 'tw-text-tertiary-300',
        };
      case 'ignored':
        return { text: 'Ignored', icon: 'fa-regular fa-eye-slash', subIcon: '', iconClass: 'tw-text-gray-300' };
      case 'deleted':
        return { text: 'Deleted', icon: 'fa-regular fa-times ', subIcon: '', iconClass: 'tw-text-error-300' };
      case 'new-needs-review':
        return {
          text: 'New Needs Review',
          icon: 'fa-solid fa-triangle-exclamation',
          subIcon: '',
          iconClass: 'tw-text-primary-300',
        };
      default:
        return { text: 'Unknown', icon: 'fa-question-circle', subIcon: '', iconClass: 'tw-text-gray-300' };
    }
  }
}
